<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    :query-selects="querySelects"
    entity-type="hundred"
    locale-section="pages.hundreds"
    field-section="hundreds"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "HundredDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: hundred
        hundred
        province {
          id
        }
        division {
          id
        }
      `,
      querySelects: /* GraphQL */ `
        province: provinces {
          id
          province
          country {
            country
          }
        }
        division: divisions {
          id
          parish
          hundred
          province
          municipality
          county
        }
      `,
      inputAttributes: {
        hundred: { required: true },
        province: { required: true },
        division: { required: true }
      }
    };
  }
};
</script>
